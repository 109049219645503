import axios from 'axios';

let electronAppUrl = 'http://localhost:3001';

function get_axios() {
  const instance = axios.create({
    baseURL: electronAppUrl,
  });

  instance.interceptors.request.use((config) => {
    // Check the condition for cancellation
    var userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf(' electron/') == -1) {
      const cancelTokenSource = axios.CancelToken.source();
      config.cancelToken = cancelTokenSource.token;
      cancelTokenSource.cancel('Request cancelled due to condition.');
    }

    return config;
  });

  return instance;
}

export default {
  getHardwareId() {
    return get_axios().get('/hardware-id');
  },
  getResourceUsage() {
    return get_axios().get('/resource-usage');
  },
  getMacAddress() {
    return get_axios().get('/mac-address');
  },
};
