import axios from 'axios';
import { BASEURL, TOKEN } from '../config';

export default () => {
  const instance = axios.create({
    baseURL: `${BASEURL}`,
  });
  instance.defaults.headers.common['Authorization'] = `${TOKEN}`;
  instance.defaults.headers.common['SOURCE'] = `KIOSK`;
  // instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

  instance.interceptors.request.use((config) => {
    // Check the condition for cancellation
    var userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf(' electron/') == -1) {
      const cancelTokenSource = axios.CancelToken.source();
      config.cancelToken = cancelTokenSource.token;
      cancelTokenSource.cancel('Request cancelled due to condition.');
    }

    return config;
  });

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );
  return instance;
};
